/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  ILocation,
  ISubpageHero,
  IFeatureText,
  ICarrerList,
  IRecruitmentTask,
  IVideoSection
} from '@standardTypes';
import { StaticImage } from 'gatsby-plugin-image';

import React from 'react';

import { Layout } from '@components/layout';
import { Feature, SubpageHero } from '@components/molecules';
import {
  CarrerList,
  RecruitmentTask,
  TextWrapperSection,
  VideoSection
} from '@components/organisms/standard';

interface CarrerPageProps {
  pageContext: {
    page: any;
    translationUrls: Record<string, string>;
  };
  location: ILocation;
}

const CarrerPage: React.FC<CarrerPageProps> = ({ pageContext }) => {
  const { page, translationUrls } = pageContext;
  const pageHero: ISubpageHero = page.ACF_CarrerPageHero;
  const textSection: IFeatureText = page.ACF_CarrerPageText;
  const carrerList: ICarrerList = page.ACF_CarrerPageList;
  const recruitmentTask: IRecruitmentTask = page.ACF_CarrerPageRecruitmentTask;
  const video: IVideoSection = page.ACF_CarrerPageVideo;
  const Background = '../../assets/images/quote_background.png';

  console.log('HERE', page);
  return (
    <Layout {...page} translationUrls={translationUrls}>
      <SubpageHero
        title={pageHero.title}
        description={pageHero.description}
        image={pageHero.image}
        additionalOptions={false}
      />
      <TextWrapperSection>
        <StaticImage src={Background} alt="background" />
        <StaticImage src={Background} alt="background" />
        <div className="text-section text-section--small-gap">
          <Feature
            image={textSection.textSectionImage}
            imageOnLeft
            title={textSection.textSectionTitle}
            subtitle={textSection.textSectionSubtitle}
            description={textSection.textSectionText}
          />
          <CarrerList acfData={carrerList} />
        </div>
      </TextWrapperSection>
      <VideoSection
        title={video.videoTitle}
        description={video.videoDescription}
        link={video.videoLink}
        poster={video.videoPoster}
      />
      <RecruitmentTask
        title={recruitmentTask.taskTitle}
        description={recruitmentTask.taskDescription}
        checkboxInfo={recruitmentTask.taskCheckboxLabel}
      />
    </Layout>
  );
};

export default CarrerPage;
